import styled from "@emotion/styled";

export const StyledFooter = styled("footer")`
  background: #f9f9f9;
  text-align: center;
  max-width: 500px;
  width: 100%;
`;

export const FooterText = styled("span")(() => ({
  fontWeight: 600,
  fontStyle: "normal",
  fontSize: 10,
  lineHeight: "10px",
}));

export const SuccessFooter = styled("footer")`
  background: #ffffff;
  text-align: center;
  max-width: 500px;
  width: 100%;
`;
