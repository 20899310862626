import { createSlice } from "@reduxjs/toolkit";

type StateType = {
  provider?: any;
  web3Provider?: any;
  address?: string | null;
  chainId?: number | null;
};

const initialState: StateType = {
  provider: null,
  web3Provider: null,
  address: null,
  chainId: null,
};

export type EventState = {
  title: string;
  location: string;
  startDate: number;
  endDate: number;
  images: Array<string>;
  witnessAddresses: Array<string>;
};

const eventState: any = { event: null };

export const eventSlice = createSlice({
  name: "event",
  initialState: eventState,
  reducers: {
    setEvents: (state, action) => {
      state.event = [
        ...action.payload.map((event: any, index: any) => {
          return {
            ...event,
            eventId: index,
          };
        }),
      ];
    },
  },
});

export const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    setProvider: (state, action) => {
      state.provider = action.payload.provider;
      state.web3Provider = action.payload.web3Provider;
      state.address = action.payload.address;
      state.chainId = action.payload.chainId;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setChainId: (state, action) => {
      state.chainId = action.payload;
    },
    resetProvider: (state) => {
      state = initialState;
    },
  },
});

export const { setProvider, setAddress, setChainId, resetProvider } =
  walletSlice.actions;

export const { setEvents } = eventSlice.actions;
