import React from "react";
import { FooterText, StyledFooter, SuccessFooter } from "./styles";

const Footer: React.FC<{ success: boolean }> = ({ success }) => {
  return (
    <>
      {success ? (
        <SuccessFooter>
          <FooterText>M81:x</FooterText>
        </SuccessFooter>
      ) : (
        <StyledFooter>
          <FooterText>M81:x</FooterText>
        </StyledFooter>
      )}
    </>
  );
};

export default Footer;
