import styled from "@emotion/styled";

export const WalletDataContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const MMLogo = styled("div")(() => ({
  width: 25,
  height: 25,
  marginRight: 10,
}));

export const AccountWalletData = styled("span")(() => ({
  fontWeight: 700,
  fontSize: 20,
  color: "#000000",
  letterSpacing: -0.408,
}));
